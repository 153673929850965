import styled from "styled-components";

export const StyledButton = styled.a`
  background-color: #5ebed7;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s ease;
  text-decoration: none;
  &:hover {
    background-color: #336699;
    cursor: pointer;
  }
`;
