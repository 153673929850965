import SchoolIcon from "@mui/icons-material/School";
import ScienceIcon from "@mui/icons-material/Science";
import CampaignIcon from "@mui/icons-material/Campaign";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import { BlockType } from "../types/types";
import { useMemo } from "react";

const Block: React.FC<BlockType> = ({ lessonShortcut, lectorName, room, lessonType, duration, height }) => {
  const blockBg = useMemo<string>(() => `bg-${lessonType}`, [lessonType]);

  const fontSizes = useMemo(() => {
    if (height >= 1) {
      return { heading: "text-lg font-bold", labels: "text-sm" };
    }
    if (height >= 1 / 2) {
      return { heading: "text-base font-bold", labels: "text-xs" };
    }
    if (height >= 1 / 3) {
      return { heading: "text-base font-bold", labels: "text-xs" };
    }
    if (height >= 1 / 4) {
      return { heading: "text-base font-bold", labels: "text-xs" };
    }
    return { heading: "", labels: "" };
  }, [height]);

  const getBlockType = () => {
    switch (lessonType) {
      case "exercise":
        return <SchoolIcon />;
      case "laboratory":
        return <ScienceIcon />;
      case "lecture":
        return <CampaignIcon />;
      case "todo":
        return <FreeBreakfastIcon />;
      case "todo2":
        return <SchoolIcon />;
      default:
        return <SchoolIcon />;
    }
  };

  return (
    <div className={`text-white w-full h-full flex gap-3 p-1 overflow-hidden ${blockBg}`}>
      {getBlockType()}
      <div className="text-left">
        <div className={fontSizes.heading}>{lessonShortcut}</div>
        {height >= 1 / 3 && <div className={fontSizes.labels}>{room}</div>}
        {height >= 1 / 2 && <div className={fontSizes.labels}>{lectorName}</div>}
      </div>
      {/* Aby sa nacitalo css */}
      <span className="bg-exercise bg-laboratory bg-lecture" />
    </div>
  );
};

export default Block;
