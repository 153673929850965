import React from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import HomeImage from "../assets/images/home-background.png";

import GlLogo from "../assets/images/GlobalLogic-logo-white.png";
import UnizaLogo from "../assets/images/Uniza-logo-white.png";
import Change from "../assets/images/vymena.svg";

import classes from "../pages/LoginPage/LoginPage.module.css";
import styled from "@emotion/styled";
import { LOGIN_INFO } from "../utils/static/constants";

const StyledTypography = styled(Typography)`
  text-align: center;
  font-size: 20px;
  color: white;
`;

type SlideWrapperProps = {
  settings: {
    dots: boolean;
    arrows: boolean;
    infinite: boolean;
    autoplay: boolean;
    speed: number;
    slidesToShow: number;
    slidesToScroll: number;
  };
};

const SliderWrapper: React.FC<SlideWrapperProps> = ({ settings }) => {
  return (
    <Box>
      <Slider {...settings}>
        <Box sx={{ display: "flex" }}>
          <img className={classes.slider_image} alt={"imageOfPc"} src={HomeImage} height="90%" width="90%" />
        </Box>
        <Box>
          <Typography align="center" p={5} sx={{ fontSize: "20px", color: "white" }}>
            Sme študenti Fakulty riadenia a informatiky. Našou snahou je vytvorenie{" "}
            <strong>jedinečnej aplikácie</strong> pre študentov Žilinskej univerzity (najskôr však iba našej fakulty),
            kde si budú môcť meniť svoj rozvrh a jednotlivé predmety. Ideou je to, aby študenti nemuseli hľadať niekoho,
            kto si taktiež bude chcieť <strong>vymeniť predmet v rozvrhu</strong>, ale budú si môcť predmety vymeniť
            jednoducho cez aplikáciu. Malo by to byť miestom, kde si môžeš predmet zmeniť a systém za Teba nájde osobu,
            ktorá chce opačnú výmenu.
          </Typography>
        </Box>
        <Box>
          {LOGIN_INFO.map((item, index) => (
            <Box key={index}>
              {index % 2 === 0 && <br />}
              <StyledTypography key={index}>{item}</StyledTypography>
            </Box>
          ))}
        </Box>
        <Box>
          <img
            className={classes.slider_image}
            alt="Vymena predmetov"
            src={Change as unknown as string}
            height="90%"
            width="90%"
          />
        </Box>
      </Slider>
      <Box mt={5} className={classes.slider_footer}>
        <img src={GlLogo} alt="Global Logic logo" height="30%" width="30%" />
        <img src={UnizaLogo} alt="Uniza logo" height="30%" width="30%" />
      </Box>
    </Box>
  );
};

export default SliderWrapper;
