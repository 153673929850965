import { Route, Routes } from "react-router-dom";
import { PATHS } from "./paths";

// PAGES
import LoginPage from "../pages/LoginPage/LoginPage";
import HomePage from "../pages/HomePage";


const RoutesConfig = [
  {
    path: PATHS.LOGIN,
    element: <LoginPage />,
  },
  {
    path: PATHS.HOME,
    element: <HomePage />,
  },
];

const Router = () => {
  return (
    <Routes>
      {RoutesConfig.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default Router;
