import React from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Grid from "./Grid";
import { colHeadings, rowHeadings } from "../../src/utils/static/constants";
import { useGetCurrentUsersTimetable } from "../hooks/queries/useLoginQuery";

const Timetable: React.FC<{}> = () => {
  const { data } = useGetCurrentUsersTimetable();

  return data ? <Grid rows={rowHeadings} columns={colHeadings} items={data} /> : <></>;
  // return <BlockGrid />;
};

export default Timetable;
