import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

type AuthContextType = {
  JWTtoken: string | null;
  loading: boolean;
  login: (username: string) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType>({
  JWTtoken: null,
  loading: true,
  login: () => {},
  logout: () => {},
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  let navigate = useNavigate();

  const [, setCookie, removeCookie] = useCookies(["JWT"]);
  const [JWTtoken, setJWTtoken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const login = async (paJWTtoken: string) => {
    try {
      setLoading(true);

      setCookie("JWT", paJWTtoken, {
        path: "/",
        maxAge: 7200, // Expires after 2 day
        sameSite: true,
      });
      setJWTtoken(paJWTtoken);

      // Tu bolo push
      navigate("/home");
      //
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      setLoading(true);

      removeCookie("JWT", { path: "/" });
      setJWTtoken(null);

      // Tu bolo push
      navigate("/signin");

      //
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return <AuthContext.Provider value={{ JWTtoken, login, logout, loading }}>{children}</AuthContext.Provider>;
};
