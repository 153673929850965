import { Subject } from "../../types/types";

export const LOGIN_TEXTs = [
  "Úprava rozvrhu podľa vaších predstáv",
  "Výmena cvičení medzi študentami",
  "Pridávanie/odoberanie predmetov",
  "Prezeranie rozvrhu predmetov",
  "Prihlasovanie pre študentov aj učiteľov",
];

export const LOGIN_INFO = [
  "Vedúci projektu: Ing. Marek Tavač,PhD.",
  "GlobalLogic podpora: Ing. Ondrej Kasaj",
  "Rok vznik projektu: 2018",
  "Aktuálna verzia projektu: 2.1.0",
  "V prípade otázok alebo pripomienok nás môžete kontaktovať: swapify.helpdesk@gmail.com",
];

export const DAY_ROWS: Subject[] = [
  {
    dayY: 0,
    lenghtW: 1,
    startX: 0,
    h: 1,
    name: "Pon",
  },
  {
    dayY: 1,
    lenghtW: 1,
    startX: 0,
    h: 1,
    name: "Utr",
  },
  {
    dayY: 2,
    lenghtW: 1,
    startX: 0,
    h: 1,
    name: "Str",
  },
  {
    dayY: 3,
    lenghtW: 1,
    startX: 0,
    h: 1,
    name: "Štr",
  },
  {
    dayY: 4,
    lenghtW: 1,
    startX: 0,
    h: 1,
    name: "Pia",
  },
];

export const colHeadings: string[] = [
  "",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
];

export const rowHeadings: string[] = ["", "Po", "Ut", "St", "Št", "Pi"];
