import { Box } from "@mui/material";
import Navbar from "../components/Navbar";
import Timetable from "../components/Timetable";
import LoginModal from "../components/Modals/LoginModal";
import {useGetUser} from "../hooks/queries/useLoginQuery";

const HomePage = () => {
  const { data } = useGetUser();

  return (
    <Box textAlign="center" display="flex" flexDirection="column" className="h-screen">
      <Navbar />
      <Timetable />
     {data?.personalNumber === "" && <LoginModal isOpen={true} />} 
    </Box>
  );
};

export default HomePage;
