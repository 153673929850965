import {
  Grid,
  Paper,
  Stack,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { LOGIN_TEXTs } from "../../utils/static/constants";
import SliderWrapper from "../../components/SliderWrapper";
import classes from "./LoginPage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import SwapifyLogo from "../../assets/images/logoblack.png";
import { StyledButton } from "../../components/Buttons/StyledButton";

const LoginPage: React.FC = () => {
  console.log("Enviroment: ", process.env.REACT_APP_ENVIROMENT);
  console.log("URL:", process.env.REACT_APP_LOGIN_URL);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
  return (
    <>
      <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ height: "100vh" }}>
        <Grid item xs={12} sm={6}>
          <div className={classes.carouselContainer}>
            <SliderWrapper settings={settings} />
          </div>
        </Grid>
        <Grid item ml={2} xs={12} sm={5} alignItems="center" justifyContent="center">
          <Box mb={2} p={2} display="flex" alignItems="center" justifyContent="center">
            <img className={classes.slider_image} alt={"Logo"} src={SwapifyLogo} height="60%" width="60%" />
          </Box>
          <Paper elevation={3} sx={{ borderRadius: "10px" }}>
            <Stack mb={10} p={2} spacing={1} sx={{ alignItems: "center" }}>
              {LOGIN_TEXTs.map((item, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faStar} color="#5ebed7" size="lg" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body1">{item}</Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </Stack>
          </Paper>
          <Box display="flex" justifyContent="center">
            <StyledButton href={process.env.REACT_APP_LOGIN_URL}>
            Prihlásiť sa
          </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};


export default LoginPage;
