import axios from "../../utils/axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { TimetableEvent, LoggedUserType } from "../../types/types";

export const createPersonalNumber = async (data: String) => {
  const { data: response } = await axios.post("/create", data);
  return response.data;
};

const getUser = async (): Promise<LoggedUserType> => {
  try {
    const { data } = await axios.get("User/GetLoggedUser");
    console.log("User ", data);
    return data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

export const useGetUser = () => {
  return useQuery({
    queryKey: ["user"],
    queryFn: getUser,
  });
};

export const useUpdatePersonalNumber = () => {
  return useMutation({
    mutationFn: (personalNumber: string) => {
      console.log("Personal number " + personalNumber);
      return axios.post(`User/UpdateCurrentUserPersonalNumber/${personalNumber}`, {
        personalNumber: personalNumber,
      });
    }, onSuccess: async () => { window.location.reload();}
  });
};

const getCurrentUsersTimetable = async (): Promise<TimetableEvent[]> => {
  const { data } = await axios.get("Timetable/GetCurrentUserTimetable");
  return data.timetableEvents;
};

export const useGetCurrentUsersTimetable = () => {
  return useQuery<TimetableEvent[], any>({
    queryKey: ["currentUsersTimetable"],
    queryFn: () => getCurrentUsersTimetable(),
  });
};
